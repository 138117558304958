<template>
  <div class="commission_money">
    <van-nav-bar
      title="预计收益"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar
      left-icon="volume-o"
      text="收益会随着订单取消或售后产生的退款而变化，仅供参考。"
    />
    <van-grid :column-num="ishehuoren == 1 ? 2 : 1" class="ketixian">
      <van-grid-item :to="{path: '/comissiontixian', query: {shareid: shareid,merchid: merchid}}">
        <template #icon>{{yongjin}}</template>
        <template #text>
          <van-cell title="可提现佣金" is-link :border="false" />
        </template>
      </van-grid-item>
      <van-grid-item v-if="ishehuoren == 1" @click="showtixian">
        <template #icon>{{chajia}}</template>
        <template #text>
          <van-cell title="可提现差价" is-link :border="false" />
        </template>
      </van-grid-item>
    </van-grid>
    <van-tabs v-model="active" @click="TabsClick">
      <van-tab v-for="item in list" :key="item.index" :title="item.title">
        <van-grid :column-num="ishehuoren == 1 ? 3 : 2">
          <van-grid-item text="预计佣金收益">
            <template #icon>{{yujiShouyi}}</template>
          </van-grid-item>
          <van-grid-item v-if="ishehuoren == 1" text="预计差价收益">
            <template #icon>{{yujiChajia}}</template>
          </van-grid-item>
          <van-grid-item text="订单数">
            <template #icon>{{orderNum}}</template>
          </van-grid-item>
        </van-grid>
      </van-tab>
    </van-tabs>
    <div v-if="!nodata">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoad"
      >
        <div class="list" v-for="(item,index) in orderList" :key="index">
          <van-cell center>
            <template #icon>
              <van-image
                :src="item.avatar"
                width="20"
                height="20"
                round
                fit="cover"
                lazy-load
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </template>
            <template #title>
              <div class="title_van-cell van-ellipsis">{{item.nickname}}</div>
            </template>
            <template #default>
              <div @click="copy(item.ordersn)">{{item.ordersn}}</div>
            </template>
            <template #right-icon><van-tag plain @click="copy(item.ordersn)">复制</van-tag></template>
          </van-cell>
          <van-card
            v-for="(val, key) in item.goods"
            :key="key"
            :num="val.total"
            :price="val.price"
            :desc="val.optionname"
            :title="val.goodstitle"
            :thumb="val.goodsthumb"
          />
          <van-cell center>
            <template #icon>{{item.status_str}}</template>
            <template #title>
              实付<span style="color: #EE0A24;">&yen;{{item.price}}</span>({{item.dispatchprice==0 ? '免运费' : '含运费：￥'+item.dispatchprice}})
            </template>
          </van-cell>
          <van-cell center>
            <template #icon>{{item.yongjinStatusStr}}</template>
            <template #title>
              <van-tag type="danger" plain>Y ({{item.agentCode}})：&yen;{{item.money_yongjin}}</van-tag>
            </template>
          </van-cell>
          <van-cell center v-if="ishehuoren==1">
            <template #icon>{{item.chajiaStatusStr}}</template>
            <template #title>
              <van-tag type="danger" plain>
                C ({{item.shopCode}})：&yen;{{item.money_chajia_real}}
              </van-tag>
            </template>
          </van-cell>
        </div>
      </van-list>
    </div>
    <div v-if="nodata">
      <van-empty description="暂无订单" />
    </div>
  </div>
</template>
<script>
export default {
  name: "CommissionMoney",
  data () {
    return {
      iswx: false,
      shareid: 0,
      merchid: 0,
      isLogin: false,
      ishehuoren: 0,
      yongjin: 0,
      chajia: 0,
      active: 0,
      list: [
        {
          index: 0,
          title: '今日',
          orderList: []
        },
        {
          index: 1,
          title: '昨日',
          orderList: [],
        },
        {
          index: 2,
          title: '近7天',
          orderList: [],
        },
        {
          index: 3,
          title: '近30天',
          orderList: [],
        },
        {
          index: 4,
          title: '本月',
          orderList: [],
        }
      ],
      yujiShouyi: 0,
      yujiChajia: 0,
      orderNum: 0,
      orderList: [],
      loading: false,
      finished: false,
      nodata: false,
      offset: 0,
      size: 10,
    }
  },
  mounted () {
    this.ishehuoren = this.$route.query.ishehuoren
    // 页面第一次加载调用的方法
    if (!window.isWeixin()) {
      this.iswx = false; // 不在微信端打开
    }else {
      this.iswx = true;
    }
    this.init()
  },
  methods: {
    init () {
      if (this.$route.query.shareid) {
        this.shareid = this.$route.query.shareid
      }else if (localStorage.getItem("DK_MEMBERID")) {
        this.shareid = localStorage.getItem("DK_MEMBERID");
      }
      this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
      this.$parent.getmerchname(this.merchid, "预计收益");

      this.isLogin = localStorage.getItem("DK_ISLOGIN") ? true : false;

      this.onLoad();
      this.getcommission();
    },
    onClickLeft () {
      this.$router.back();
    },
    onLoad () {
      let that = null;
      that = this;
      let showToast = null;
      showToast = {};
      if (that.offset == 0) {
        showToast = that.$toast({
          type: 'loading',
          message: '加载中...'
        })
      }
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(
          that.$store.state.domain + 'web/commission/getYujiList',
          that.$qs.stringify({
            active: that.active,
            offset: that.offset,
            size: that.size,
            ishehuoren: that.ishehuoren
          })
        )
        .then(res => {
          if (that.offset == 0) {
            showToast.clear();
          }
          console.log(res, "success");
          that.loading = false;
          that.finished = false;
          that.nodata = false;
          if (res.data.code == 100000) {
            if (that.offset == 0) {
              that.orderList = res.data.data.list;
              that.yujiShouyi = res.data.data.yujiYongjin;
              that.yujiChajia = res.data.data.yujiChajia;
              that.orderNum = res.data.data.orderNum;
            }else {
              res.data.data.list.forEach(item => {
                that.orderList.push(item);
              }) 
            }
            if (res.data.data.list.length < that.size) {
              that.finished = true;
            }
            that.offset += res.data.data.list.length;
          }else {
            that.finished = true;
            if (that.offset == 0) {
              that.nodata = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
          that.finished = true;
          if (that.offset == 0) {
            showToast.clear();
            that.nodata = true;
          }
        })
    },
    // 获取可提现佣金和可提现差价
    getcommission() {
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(
          that.$store.state.domain + 'web/commission/getYujiCommission',
          that.$qs.stringify({
            ishehuoren: that.ishehuoren
          })
        )
        .then(res => {
          if (res.data.code == 100000) {
            that.yongjin = res.data.data.yongjin;
            that.chajia = res.data.data.chajia;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 复制
    copy(text) {
      let _that = null;
        _that = this;
        _that.$copyText(text).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
    },
    TabsClick(name,title) {
      console.log(name, title);
      this.active = name;
      this.offset = 0;
      this.onLoad();
    },
    showtixian() {
      window.location.href="https://shop.midbest.cn/?page=tixian&merchid=" + this.merchid + "&logincode=" + localStorage.getItem("DK_UID");
    },
  }
}
</script>
<style lang="less">
.commission_money {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  overflow: hidden;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .ketixian {
    .van-cell {
      justify-content: center;
    }
  }
  .van-tabs__content {
    .van-grid-item__text {
      margin-top: 8px;
    }
  }
  .van-list {
    padding-bottom: 50px;
  }
  .list {
    overflow: hidden;
    margin: 10px 0;
    .van-cell {
      overflow: hidden;
      .van-image {
        display: block;
        margin-right: 10px;
      }
      .van-cell__title {
        flex: 1;
        text-align: right;
        overflow: hidden;
        margin-right: 5px;
      }
      .title_van-cell {
        overflow: hidden;
        text-align: left;
      }
      .van-tag {
        margin-left: 5px;
      }
    }
    .van-card {
      margin-top: 0;
      background-color: #fff;
      text-align: left;
      .van-card__price {
        color: #EE0A24;
      }
    }
  }
}
</style>